// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var pageContainer = Cx.cx([
      "flex",
      "flex-col",
      "items-center"
    ]);

var layout = Cx.cx([
      "grid",
      "lg:max-w-screen-xl",
      "m-auto",
      "w-full",
      "p-2",
      "mt-5",
      "gap-2"
    ]);

var header = Cx.cx([
      "sm:mt-5",
      "sm:mb-2.5",
      "flex",
      "justify-between",
      "items-center",
      "flex-wrap"
    ]);

var subHeader = Cx.cx([
      "sm:mb-2.5",
      "flex",
      "justify-between",
      "md:items-center",
      "flex-wrap",
      "md:flex-row",
      "flex-col",
      "gap-2"
    ]);

var headerControls = Cx.cx([
      "flex",
      "flex-wrap",
      "my-2",
      "gap-4",
      "items-center",
      "max-md:*:w-full"
    ]);

var searchField = Cx.cx([
      "py-2",
      "pl-15",
      "md:min-w-96",
      "w-full"
    ]);

var dropdown = Cx.cx([
      "py-2",
      "!text-sm",
      "cursor-pointer",
      "min-w-40"
    ]);

var dropdownBody = Cx.cx([
      "grid",
      "grid-cols-[minmax(160px,1fr)]",
      "grid-flow-row",
      "auto-rows-max",
      "flex-col",
      "gap-1",
      "pt-0",
      "pb-0",
      "max-h-64",
      "overflow-y-auto",
      "min-w-72",
      "text-sm"
    ]);

var dropdownBodyLinkDisabled = Cx.cx(["text-gray-400"]);

var menu = Cx.cx([
      "flex",
      "flex-wrap",
      "items-center",
      "justify-start"
    ]);

var dropdownBodyRow = Cx.cx([
      "grid",
      "grid-cols-[max-content_1fr]",
      "gap-1",
      "items-center",
      "py-1",
      "border-b",
      "border-gray-100",
      "last:border-b-0"
    ]);

var label = Cx.cx([
      "m-0",
      "break-words",
      "hyphens-auto"
    ]);

var dropdownBodyLink = Cx.cx([
      "text-inherit",
      "no-underline",
      "hover:text-teal"
    ]);

var approvedProviderMenu = Cx.cx([
      "flex",
      "flex-row",
      "items-center",
      "justify-start"
    ]);

var badge = Cx.cx([
      "inline-flex",
      "items-center",
      "justify-center",
      "rounded-lg",
      "px-3",
      "py-1",
      "text-sm",
      "text-white"
    ]);

var fetchError = Cx.cx([
      "text-red-600",
      "text-center"
    ]);

var actionBarInner = Cx.cx([
      "flex",
      "gap-1",
      "flex-row",
      "items-center",
      "justify-between",
      "px-7",
      "w-full"
    ]);

var actionBarInfo = Cx.cx([
      "flex",
      "flex-wrap",
      "flex-col",
      "text-white",
      "text-sm",
      "md:text-base",
      "items-start"
    ]);

var actionBarSpinner = Cx.cx([
      "flex",
      "relative",
      "min-w-16"
    ]);

var actionBarError = Cx.cx([
      "grid",
      "justify-end",
      "text-red-300",
      "font-bold"
    ]);

var table = Cx.cx([
      "mb-5",
      "table-auto",
      "w-full"
    ]);

var tableWrapper = Cx.cx([
      "overflow-x-auto",
      "min-h-64"
    ]);

var selected = Cx.cx([
      "grid",
      "items-center"
    ]);

var controlCell = Cx.cx([
      "flex",
      "items-center",
      "gap-1",
      "font-semibold",
      "text-gray-700"
    ]);

var actionBar = Cx.cx([
      "fixed",
      "bottom-0",
      "left-0",
      "right-0",
      "h-20",
      "bg-teal",
      "transition-transform",
      "z-[9999]",
      "flex"
    ]);

var tableLink = Cx.cx([
      "text-teal",
      "font-semibold",
      "hover:underline"
    ]);

var options = Cx.cx([
      "flex",
      "flex-row",
      "gap-4"
    ]);

var title = "m-0";

var controlRightAlign = "justify-end";

var pagination = "mb-10";

var actionBarDropdown = "bg-white";

var spinner = "min-h-40";

var shown = "translate-y-0";

var hidden = "translate-y-full";

export {
  pageContainer ,
  layout ,
  header ,
  title ,
  subHeader ,
  headerControls ,
  searchField ,
  dropdown ,
  dropdownBody ,
  dropdownBodyLinkDisabled ,
  menu ,
  dropdownBodyRow ,
  label ,
  dropdownBodyLink ,
  approvedProviderMenu ,
  controlRightAlign ,
  badge ,
  fetchError ,
  pagination ,
  actionBarInner ,
  actionBarInfo ,
  actionBarDropdown ,
  actionBarSpinner ,
  actionBarError ,
  table ,
  tableWrapper ,
  selected ,
  controlCell ,
  spinner ,
  actionBar ,
  shown ,
  hidden ,
  tableLink ,
  options ,
}
/* pageContainer Not a pure module */
